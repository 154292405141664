<template>
    <div class="aa-noticia-grid" @click="ir">
        <div class="aa-noticia-grid-img" :style="'background-image: url(' + data.portada + ')'">
            <div class="aa-noticia-grid-titulo">
                <div class="pt-1 px-1">
                    {{data.titulo}}
                <br>
                <span v-if="data.curso">{{data.curso}}</span>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { useRouter } from 'vue-router'
export default {
props: {
    datos: {
        type: Object,
        default(){
            return {}
        }
    },
    url: {
        type: String,
        required: true
    }
},
setup(props){
    const router = useRouter()
    
    return {
        data: props.datos,
        donde: props.url,
        ir: () => router.push({ name: props.url, params: { id: props.datos.id } })
    }
}

}
</script>

<style lang="sass" scoped>
.aa-noticia-grid
    $root:&
    cursor: pointer
    @include breakpoint(lg)
        width: 45%
        margin: 0 auto
    &-img
        position: relative
        height: 300px
        background-position: center
        background-size: cover
        width: 100%
        display: flex
        flex-direction: column
        flex-wrap: wrap
        justify-content: flex-end
        background-color: rgb(0 0 0 / 10%)
        background-blend-mode: darken
    &-titulo
        position: absolute
        width: 100%
        background-color: #012264cc
        font-weight: 900
        min-height: 85px
        text-shadow: 1px 1px 2px #000
        text-align: start
        color: #fff
</style>

